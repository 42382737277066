<template lang = "pug">
div
  fi-form-field(:label = '$t("reportingType")' :label-cols = 3 )
    fi-select(
      v-model = 'immovableData.assetReportingType'
      :options       = 'reportingTypes'
      sm
    )
  .loan-collateral-asset-immovable-fields-edit.animated.fadeIn.flex-row-direction
    div.width-50.margin-right-20
      fi-form-field(:label = '$t("registerNumber")')
        fi-loadable-select(
            v-model      = 'selectedRegNumberModel'
            :placeholder = '$t("existingRegisterNumberPlaceholder")'
            flag         = true
            @change      = 'handleChangeRegNumber'
        )
      fi-form-field(:label = '$t("type")')
        fi-select(
          v-model.number = 'immovableData.warrantyTypeId'
          :options       = 'optionsFromClassifier("warrantyTypes")'
          sm
        )
      fi-form-field.animated.fadeIn(
        v-if   = 'isShowName'
        :label = '$t("immovableName")'
      )
        input.form-control.form-control-sm(
          v-model.number = 'immovableData.immovableName'
          type           = 'text'
        )
      fi-form-field(:label = '$t("warrantyCadastralUnitIdentifier")')
        input.form-control.form-control-sm(
          v-model = 'immovableData.warrantyCadastralUnitIdentifier'
          type    = 'text'
        )
      fi-form-field(:label = '$t("country")')
        fi-select(
          v-model.number = 'immovableData.warrantyCountryId'
          :options       = 'optionsFromClassifier("countries")'
          sm
        )
      fi-form-field(:label = '$t("county")')
        fi-select(
          v-model.number = 'immovableData.warrantyCountyId'
          :options       = 'counties'
          sm
        )
      fi-form-field(:label = '$t("city")')
        input.form-control.form-control-sm(
          v-model.number = 'immovableData.warrantyCity'
          type           = 'text'
        )
      fi-form-field(:label = '$t("address")')
        input.form-control.form-control-sm(
          v-model.number = 'immovableData.warrantyAddress'
          type           = 'text'
        )
      fi-form-field(:label = '$t("postalСode")')
        input.form-control.form-control-sm(
          v-model.number = 'immovableData.warrantyPostalCode'
          type           = 'text'
        )
      fi-form-field(:label = '$t("warrantyOwnerName")')
        .flex-row-direction
          fi-customer-select(
            v-model      = 'selectedCustomerModel'
            :placeholder = '$t("existingCustomerPlaceholder")'
            :customers   = 'customerOptions'
          )
          .input-group-append
            button.btn.btn-sm.btn-primary(
              @click.prevent = 'addSelectedUserToOwnerList'
            )
              i.far.fa-plus-square.fa-lg
      div
        span.pr-2.bold.offset-sm-6 - {{ $t('or') }} -
        customer-new-brief(
          v-if    = 'createNewCustomer'
          @cancel = 'createNewCustomer = false'
          @submit = 'onCreateCustomer'
        )
        button.btn.btn-link.px-0(
          v-else
          @click.prevent = 'createNewCustomer = true'
        ) {{ $t('createCustomer') }}
        .flex-column-direction(v-if = 'showOwnerList')
          <table style="width:100%">
            <tr style = 'text-align: center;'>
              <th style = 'width: 50%'></th>
              <th style = 'width: 10%'>{{ $t("primary") }}</th>
              <th style = 'width: 20%'>{{ $t("ownerShip") }}</th>
              <th style = 'width: 10%'></th>
            </tr>
            template(v-for = '(owner, index) in assetOwner')
              <tr>
                <td class = 'bold'> {{ owner.customerName }} </td>
                <td style = 'display: flex;'>
                  input(
                    style   = 'width: 100%; height: 35px;'
                    v-model = 'owner.primaryOwner'
                    type    = 'checkbox'
                    @change = 'selectOwnerPrimary(index)'
                  )
                </td>
                <td>
                  percent-input(
                    v-model = 'owner.ownershipPercentage')
                </td>
                <td style = 'text-align: center;'>
                  i.far.fa-trash-alt.fa-lg.text-danger.cursor-pointer(
                    @click = 'removeOwnerFromOwnerList(index)'
                  )
                </td>
              </tr>
          </table>
      fi-form-field(:label = '$t("assetInfo")')
        fi-rich-text-editor(v-model = 'assetData.assetInfo')

    div.width-50
      fi-form-field(:label = '$t("valueType")')
        fi-select(
          v-model.number = 'immovableData.valueType'
          :options       = 'valueTypes'
          sm
        )
      fi-form-field(:label = '$t("valuationType")')
        fi-select(
          v-model.number = 'immovableData.valuationType'
          :options       = 'valuationTypes'
          sm
        )
      fi-form-field(:label = '$t("locationCode")')
        fi-select(
          v-model.number = 'immovableData.collateralLocationCode'
          :options       = 'locationCodes'
          sm
        )
      fi-form-field(:label = '$t("assetStatus")')
        fi-select(
          v-model.number = 'immovableData.assetStatus'
          :options       = 'assetStatus'
          sm
        )
      //- fi-form-field(:label = '$t("appraisalPartner")')
      //-   fi-customer-select(
      //-     v-model = 'selectedAppraisalPartnerModel'
      //-     flag    = true
      //-   )
      fi-form-field(:label = '$t("assetSoldValue")')
        input.form-control.form-control-sm(
          v-model.number = 'immovableData.assetSoldValue'
          type           = 'text'
        )
      fi-form-field(:label = '$t("soldDate")')
        fi-datepicker(
          v-model = 'immovableData.soldDate'
          sm
        )
      fi-form-field(:label = '$t("warrantyArea")')
        .form-row
          .col
            input.form-control.form-control-sm(
              v-model.number = 'immovableData.warrantyArea'
              type           = 'text'
            )
          .col
            fi-select(
              v-model.number = 'immovableData.areaSizeTypeId'
              :options       = 'optionsFromClassifier("immovableAreaSizeType")'
              required
              sm
            )
      fi-form-field(
        :label = '$t("value")'
        :state = '!state.assetData.value.$error'
      )
        template(#error)
          .error-message(v-if = '!state.assetData.value.required') {{ $t('common:required') }}
        fi-money-input.form-control.form-control-sm(
          v-model.number = 'assetData.value'
          :class         = '{ "is-invalid": state.assetData.value.$error }'
        )
      fi-form-field(:label = '$t("valueDate")')
        fi-datepicker(
          v-model = 'assetData.valueDate'
          sm
        )
      fi-form-field(
        :label = '$t("allocatedValue")'
      )
        fi-money-input.form-control.form-control-sm(
          v-model.number = 'assetData.allocatedValue'
        )
      fi-form-field(:label = '$t("appraisalReport")')
        fi-switch.mt-1(
          v-model   = 'assetData.appraisalReport'
          :on-text  = '$t("common:yes")'
          :off-text = '$t("common:no")'
        )
      .animated.fadeIn(v-if = 'assetData.appraisalReport')
        fi-form-field(:label = '$t("appraisalPartnerName")')
          input.form-control.form-control-sm(
            v-model.number = 'assetData.appraisalPartnerName'
            type           = 'text'
          )
        fi-form-field(:label = '$t("appraisalValue")')
          fi-money-input.form-control.form-control-sm(
            v-model.number = 'assetData.appraisalValue'
            type           = 'text'
          )
      fi-form-field(:label = '$t("insuranceMandatory")')
        fi-switch.mt-1(
          v-model   = 'assetData.insuranceMandatory'
          :on-text  = '$t("common:yes")'
          :off-text = '$t("common:no")'
        )
      fi-form-field(:label = '$t("insuranceCompany")')
        input.form-control.form-control-sm(
          v-model.number = 'assetData.insuranceCompany'
          type           = 'text'
        )
      fi-form-field(:label = '$t("insuredUntil")')
        fi-datepicker(
          v-model = 'assetData.insuredUntil'
          sm
        )
</template>


<script>
import FiDatepicker from '@/components/FiDatepicker'
import FiFormField from '@/components/FiFormField'
import FiMoneyInput from '@/components/FiMoneyInput'
import FiRichTextEditor from '@/components/FiRichTextEditor'
import FiSelect from '@/components/FiSelect'
import FiLoadableSelect from '@/components/FiLoadableSelect'
import FiSwitch from '@/components/FiSwitch'
import FiCustomerSelect from '@/components/FiCustomerSelect'
import PercentInput from '@/components/PercentInput'
import CustomerNewBrief from '@/views/customers/CustomerNewBrief'
import { mapGetters, mapState } from 'vuex'
import api from '@/api'

export default {
  name: 'loan-collateral-asset-immovable-fields-edit',

  components: {
    FiRichTextEditor,
    FiMoneyInput,
    FiFormField,
    FiSelect,
    FiLoadableSelect,
    FiDatepicker,
    FiSwitch,
    FiCustomerSelect,
    CustomerNewBrief,
    PercentInput
  },

  props: {
    immovableData: {
      type: Object,
      required: true
    },
    assetData: {
      type: Object,
      required: true
    },
    state: {
      type: Object,
      required: true
    },
    appraisalPartnerData: {
      type: Object,
      default: null
    },
    assetOwner: {
      type: Array,
      default: () => []
    }
  },

  i18nOptions: {},

  data: (vm) => ({
    createNewCustomer: false,
    selectedCustomer: null,
    selectedRegNumber: null,
    selectedAppraisalPartner: vm.appraisalPartnerData,
    showOwnerList: false,
    deleting: false,
    ownerList: [],
    assetOwnerData: [],
    selectedRegNumberData: {},
    customerOptions: []
  }),

  computed: {
    ...mapState('loans', ['loan']),
    ...mapGetters('classifiers', ['optionsFromClassifier', 'optionsFromCountiesByCountry', 'classifierById']),
    counties () {
      return this.optionsFromCountiesByCountry(this.immovableData.warrantyCountryId)
    },
    assetStatus () {
      return this.optionsFromClassifier('ASSET_STATUS', true)
    },
    reportingTypes () {
      return this.optionsFromClassifier('TYP_PRTCTN', true)
    },
    valueTypes () {
      return this.optionsFromClassifier('TYP_PRTCTN_VL', true)
    },
    valuationTypes () {
      return this.optionsFromClassifier('PRTCTN_VLTN_APPRCH', true)
    },
    locationCodes () {
      return this.optionsFromClassifier('ISO3166_NUTS_DSJNT', true)
    },
    selectedCustomerModel: {
      get () {
        return this.selectedCustomer
      },
      async set (selected) {
        this.selectedCustomer = selected
      }
    },
    selectedRegNumberModel: {
      get () {
        return this.selectedRegNumber
      },
      async set (selected) {
        if (selected) {
          this.selectedRegNumber = selected
          await this.inputFieldsWithRegNumber(selected)
        } else {
          this.selectedRegNumber = null
          this.immovableData.registerNumber = ''
        }
      }
    },
    selectedAppraisalPartnerModel: {
      get () {
        return this.selectedAppraisalPartner
      },
      set (selected) {
        if (selected) {
          this.selectedAppraisalPartner = selected
          this.immovableData.appraisalPartnerId = selected.id
        } else {
          this.selectedAppraisalPartner = null
          this.immovableData.appraisalPartnerId = ''
        }
      }
    },
    isShowName () {
      return /land|lot/i.test(this.classifierById('warrantyTypes', this.immovableData.warrantyTypeId).code)
    },
    lengthOwnerList () {
      return this.assetOwner.length
    }
  },

  watch: {
    lengthOwnerList: {
      handler (value) {
        value ? this.showOwnerList = true : this.showOwnerList = false
      },
      deep: true
    }
  },

  async created () {
    if (this.immovableData.assetsOwners.length) {
      this.assetOwnerData = this.immovableData.assetsOwners
      this.showOwnerList = true
      this.ownerList = this.immovableData.assetsOwners
    }
    if (!this.assetData.value) {
      if (this.appraisalPartnerData) {
        this.selectedAppraisalPartner = this.appraisalPartnerData
      }
    }
    await this.prefillRegNumber()
  },

  methods: {
    async onCreateCustomer ({ customer }) {
      this.createNewCustomer = false
      this.selectedCustomerModel = customer
    },
    addSelectedUserToOwnerList () {
      if (this.selectedCustomer) {
        this.assetOwner.push({ customerName: this.selectedCustomer.customerName, primaryOwner: false, ownershipPercentage: 0, customerId: this.selectedCustomer.id })
      }
    },
    selectOwnerPrimary (index) {
      if (this.assetOwner[index].primary) {
        this.assetOwner.forEach((el, id) => {
          if (index !== id) {
            el.primary = false
          }
        })
      }
    },
    removeOwnerFromOwnerList (index) {
      this.assetOwner.splice(index, 1)
    },
    async inputFieldsWithRegNumber (value) {
      const { data } = await api.getLoanImmovablesRegNumbers({ criteria: value ? value.text.split(' ')[0] : '' })
      if (data.length) {
        this.selectedRegNumberData = data[0]
        for (const key of Object.keys(this.selectedRegNumberData)) {
          if (key in this.assetData) {
            this.assetData[key] = this.selectedRegNumberData[key]
          } else if (key in this.immovableData) {
            this.immovableData[key] = this.selectedRegNumberData[key]
          }
        }
        if (this.selectedRegNumberData.assetOwners) {
          this.customerOptions = this.selectedRegNumberData.assetsOwners
        }
      } else {
        this.immovableData.registerNumber = value.text
      }
      this.assetData.creditContractInfos = [{ contractNumber: this.loan.agreementNumber, customerName: this.loan.customerBorrower.customerName }]
    },
    handleChangeRegNumber (value) {
      this.assetData.creditContractInfos = [{ contractNumber: this.loan.agreementNumber, customerName: this.loan.customerBorrower.customerName }]
      this.selectedRegNumber = value
      this.immovableData.registerNumber = value.text
    },
    async getRegisterNumber (value) {
      const { data } = await api.getLoanImmovablesRegNumbers({ criteria: value })
      return data[0]
    },
    async prefillRegNumber () {
      if (this.immovableData.registerNumber) {
        const registerNumber = await this.getRegisterNumber(this.immovableData.registerNumber)
        if (registerNumber.creditContractInfos) {
          let name = ''
          if (registerNumber.creditContractInfos.length) {
            if (registerNumber.creditContractInfos[0].customerName) {
              name = `(${registerNumber.creditContractInfos[0].customerName})`
            }
          }
          if (registerNumber.creditContractInfos?.customerName) {
            name = `(${registerNumber.creditContractInfos?.customerName})`
          }
          this.selectedRegNumber = { text: `${registerNumber.registerNumber} ${name} ${registerNumber.creditContractInfos.contractNumber || registerNumber.creditContractInfos[0].contractNumber || ''}`, value: registerNumber.id }
        } else {
          this.selectedRegNumber = { text: this.immovableData.registerNumber, value: registerNumber?.id }
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.flex-row-direction {
  display: flex;
}
.flex-column-direction {
  display: flex; flex-direction: column;
}
.bold {
  font-weight: bold;
}
.cursor-pointer {
  cursor: pointer;
}
.justify-content-space-evenly {
  justify-content: space-evenly;
}
.width-50 {
  width: 50%;
}
.margin-right-20 {
  margin-right: 20px
}
</style>

<i18n>
en:
  existingCustomerPlaceholder:       "Select customer"
  existingRegisterNumberPlaceholder: "Select number"
  registerNumber:                    "Register number"
  type:                              "Immovable type"
  immovableName:                     "Immovable name"
  country:                           "Country"
  county:                            "County"
  createCustomer:                    "Create customer"
  city:                              "City"
  address:                           "Address"
  value:                             "Value"
  valueDate:                         "Value date"
  allocatedValue:        "Allocated value"
  appraisalReport:                   "Appraisal report"
  appraisalPartnerName:              "Appraisal partner"
  appraisalValue:                    "Appraisal value"
  insuranceMandatory:                "Insurance mandatory"
  insuranceCompany:                  "Insurance company"
  insuredUntil:                      "Insured until"
  assetInfo:                         "Asset info"
  warrantyCadastralUnitIdentifier:   "Cadastral unit identifier"
  warrantyArea:                      "Area"
  warrantyOwnerName:                 "Owner name"
  primary:                           "Primary"
  ownerShip:                         "Ownership"
  postalСode:                        "Postal code"
  soldDate:                          "Sold date"
  assetSoldValue:                    "Asset sold value"
  appraisalPartner:                  "Appraisal partner"
  reportingType:                     "Reporting type"
  valueType:                         "Value type"
  valuationType:                     "Valuation type"
  locationCode:                      "Location code"
  assetStatus:                       "Asset status"
et:
  existingCustomerPlaceholder:       "Select customer"
  existingRegisterNumberPlaceholder: "Select number"
  registerNumber:                    "Register number"
  type:                              "Immovable type"
  immovableName:                     "Immovable name"
  country:                           "Country"
  county:                            "County"
  createCustomer:                    "Create customer"
  city:                              "City"
  address:                           "Address"
  value:                             "Value"
  valueDate:                         "Value date"
  allocatedValue:        "Allocated value"
  appraisalReport:                   "Appraisal report"
  appraisalPartnerName:              "Appraisal partner"
  appraisalValue:                    "Appraisal value"
  insuranceMandatory:                "Insurance mandatory"
  insuranceCompany:                  "Insurance company"
  insuredUntil:                      "Insured until"
  assetInfo:                         "Asset info"
  warrantyCadastralUnitIdentifier:   "Cadastral unit identifier"
  warrantyArea:                      "Area"
  warrantyOwnerName:                 "Owner name"
  primary:                           "Primary"
  ownerShip:                         "Ownership"
  postalСode:                        "Postal code"
  soldDate:                          "Sold date"
  assetSoldValue:                    "Asset sold value"
  appraisalPartner:                  "Appraisal partner"
  reportingType:                     "Reporting type"
  valueType:                         "Value type"
  valuationType:                     "Valuation type"
  locationCode:                      "Location code"
  assetStatus:                       "Asset status"
ru:
  existingCustomerPlaceholder:       "Select customer"
  existingRegisterNumberPlaceholder: "Select number"
  registerNumber:                    "Register number"
  type:                              "Immovable type"
  immovableName:                     "Immovable name"
  country:                           "Country"
  county:                            "County"
  createCustomer:                    "Create customer"
  city:                              "City"
  address:                           "Address"
  value:                             "Value"
  valueDate:                         "Value date"
  allocatedValue:        "Allocated value"
  appraisalReport:                   "Appraisal report"
  appraisalPartnerName:              "Appraisal partner"
  appraisalValue:                    "Appraisal value"
  insuranceMandatory:                "Insurance mandatory"
  insuranceCompany:                  "Insurance company"
  insuredUntil:                      "Insured until"
  assetInfo:                         "Asset info"
  warrantyCadastralUnitIdentifier:   "Cadastral unit identifier"
  warrantyArea:                      "Area"
  warrantyOwnerName:                 "Owner name"
  primary:                           "Primary"
  ownerShip:                         "Ownership"
  postalСode:                        "Postal code"
  soldDate:                          "Sold date"
  assetSoldValue:                    "Asset sold value"
  appraisalPartner:                  "Appraisal partner"
  reportingType:                     "Reporting type"
  valueType:                         "Value type"
  valuationType:                     "Valuation type"
  locationCode:                      "Location code"
  assetStatus:                       "Asset status"
</i18n>
