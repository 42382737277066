<template lang="pug">
div
  fi-multiselect.fi-loadable-select(
    v-model          = 'regNumberModel'
    :options         = 'regNumberOptions'
    :search-callback = 'findRegNumbers'
    v-bind           = '$attrs'
  )
  button.cross(
    v-if           = 'flag && regNumberModel'
    @click.prevent = '$emit("input", null)'
  ) {{ 'x' }}
</template>

<script>
import FiMultiselect from '@/components/FiMultiselect'
import api from '@/api'

export default {
  name: 'fi-loadable-select',

  components: { FiMultiselect },

  inheritAttrs: false,

  model: {
    prop: 'regNumber'
  },

  props: {
    regNumber: {
      type: Object,
      default: null
    },
    flag: {
      type: Boolean,
      default: false
    }
  },

  data: () => ({
    regNumberOptions: []
  }),

  computed: {
    regNumberModel: {
      get () {
        if (this.regNumber) {
          return this.regNumber
        } else {
          return null
        }
      },
      set (selectedRegNumber) {
        this.$emit('input', selectedRegNumber)
      }
    }
  },

  methods: {
    async findRegNumbers ({ query }) {
      if (!query) return
      this.$emit('change', { text: query })
      const { data } = await api.getLoanImmovablesRegNumbers({ criteria: query })
      if (data.length) {
        this.regNumberOptions = data.map(el => {
          if (el.creditContractInfos) {
            let name = ''
            if (el.creditContractInfos.length) {
              if (el.creditContractInfos[0].customerName) {
                name = `(${el.creditContractInfos[0].customerName})`
              }
            }
            if (el.creditContractInfos?.customerName) {
              name = `(${el.creditContractInfos?.customerName})`
            }
            return { text: `${el.registerNumber} ${name} ${el.creditContractInfos.contractNumber || el.creditContractInfos[0].contractNumber || ''}`, value: el.id }
          } else {
            return { text: el.registerNumber, value: el.id }
          }
        })
      } else {
        this.regNumberOptions = [{ text: query, value: query }]
      }
      return this.regNumberOptions[0]
    }
  }
}
</script>

<style lang='scss'>
div {
  position: relative;

  .cross {
    position: absolute;
    width: fit-content;
    height: fit-content;
    background-color: transparent;
    padding: 0;
    border: none;
    outline: none;
    left: 90%;
    top: 50%;
    transform: translate(0, -50%);
    font-weight: bold;
  }
}
</style>
